<template>
  <v-app>
    {{dynamicUserId}}
    <dashboard-core-app-bar v-model="expandOnHover" :liveFeed="fetchedOrLiveFeed" :fetchedOrLiveUser="fetchedOrLiveUser" value=false />

    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" :liveFeed="fetchedOrLiveFeed" :fetchedOrLiveUser="fetchedOrLiveUser" />

    <dashboard-core-view :liveFeed="fetchedOrLiveFeed" :fetchedOrLiveUser="fetchedOrLiveUser" />

    <!-- <dashboard-core-settings v-model="expandOnHover" /> -->
  </v-app>
</template>

<script>
import { SUBSCRIBE_USER, SUBSCRIBE_LIVEFEED } from '@/queries'
import { mapGetters } from 'vuex'

export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    /* DashboardCoreSettings: () => import("./components/core/Settings"), */
    DashboardCoreView: () => import("./components/core/View"), 
  },

  apollo: {
    $subscribe: {
      getUserSub: {
        query: SUBSCRIBE_USER,
        variables: function () {
          return {
            userID: this.dynamicUserId
          }
        },
        result({ data }) {
          this.liveUser = data.getUserSub
        }
      },
      getUser: {
        query: SUBSCRIBE_LIVEFEED,
        result({ data }) {
          this.liveFeed = data.getLiveFeed;
        },
      },
    },
  },
  data: () => ({
    expandOnHover: false,
    liveFeed : [],
    liveUser : {}
  }),
  computed: {
    ...mapGetters(['currentUser', 'getFeed']),

    dynamicUserId() {
      if (this.currentUser) {
        console.log('dynamicUserId: ', this.currentUser._id)
        return this.currentUser._id
      }
      console.log('dynamicUserId: ', null)
      return null
    },

    fetchedOrLiveFeed() {
          if (this.liveFeed.length > 0) {
            return this.liveFeed
          }
          return this.getFeed
    },

    fetchedOrLiveUser() {
          if (Object.keys(this.liveUser).length > 0) {
            return this.liveUser
          }
          return this.currentUser
    },
  }
};
</script>
